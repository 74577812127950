<template>
  <tabs class="h-full details-tabs" :on-select="onSelect">
    <tab title="General">
      <table class="table is-top mt-4 min-w-1/2">
        <tr>
          <td><strong v-translate>Name</strong></td>
          <td>{{ name }}</td>
        </tr>
        <tr>
          <td><strong v-translate>Database</strong></td>
          <td>{{ database }}</td>
        </tr>
        <tr>
          <td><strong v-translate>Schema</strong></td>
          <td>{{ schema }}</td>
        </tr>
        <tr>
          <td><strong v-translate>Table ID</strong></td>
          <td>
            <span>{{ id }}</span>
            <yb-clipboard-icon v-tooltip="'Copy table id to clipboard'" :value="id" />
          </td>
        </tr>

        <template v-if="!loading">
          <tr>
            <td><strong v-translate>Distribution Type</strong></td>
            <td>{{ item.distributionType || item.distribution }}</td>
          </tr>
          <tr v-if="item.distributionKey || item.distribution_key">
            <td><strong v-translate>Distribution Key</strong></td>
            <td>{{ item.distributionKey || item.distribution_key }}</td>
          </tr>
          <tr v-if="item.sortKey || item.sort_key">
            <td><strong v-translate>Sort Key</strong></td>
            <td>{{ item.sortKey || item.sort_key }}</td>
          </tr>
          <tr v-if="item.clusterKeys || item.cluster_keys">
            <td><strong v-translate>Cluster Keys</strong></td>
            <td>{{ item.clusterKeys || item.cluster_keys }}</td>
          </tr>
          <tr v-if="item.partitionKeys || item.partition_keys">
            <td><strong v-translate>Partition Keys</strong></td>
            <td>{{ item.partitionKeys || item.partition_keys }}</td>
          </tr>
          <tr>
            <td><strong v-translate>Row Count</strong></td>
            <td>
              <span>{{ number(item.rows) }}</span>&nbsp;<span v-translate class="smc-undetail">(across all file systems)</span>
            </td>
          </tr>
          <tr>
            <td><strong v-translate>Rows Pending Insert</strong></td>
            <td>
              <span v-if="item.pendingRows">{{ number(item.pendingRows) }} rows
                <span v-if="item.pendingSize"> / {{ bytes(item.pendingSize) }}</span>
              </span>
              <span v-else v-translate>None</span>
            </td>
          </tr>
          <tr>
            <td><strong v-translate>Total Size</strong></td>
            <td>
              <span>{{ bytes(item.bytesUsed) }}</span>&nbsp;<span v-translate class="smc-undetail">(across all file systems)</span>
            </td>
          </tr>
          <tr>
            <td><strong v-translate>Total Size (Uncompressed)</strong></td>
            <td>
              <span>{{ bytes(item.bytesUsedUncompressed) }} ({{ number((item.bytesUsedUncompressed / item.bytesUsed), 1) }}{{ translate(' compression ratio') }})</span>&nbsp;<span v-translate class="smc-undetail">(across all file systems)</span>
            </td>
          </tr>
          <tr v-if="item.size">
            <td><strong v-translate>Size</strong></td>
            <td>
              <span>{{ bytes(item.size) }}</span>&nbsp;<span v-translate class="smc-undetail">(on this file system)</span>
            </td>
          </tr>
          <tr v-if="item.fileCount">
            <td><strong v-translate>Shards</strong></td>
            <td>
              <span>{{ number(item.fileCount) }}</span>&nbsp;<span v-translate class="smc-undetail">(across all file systems)</span>
            </td>
          </tr>
          <tr v-if="item.partitionKeys">
            <td><strong v-translate>Partition Summary</strong></td>
          </tr>
          <tr v-if="item.partitionKeys">
            <td colspan="2">
              <div v-if="loadingPartitions" v-translate>
                (Loading Partition Information)
              </div>
              <table v-if="partitionSummary" class="table is-narrow">
                <thead>
                  <tr>
                    <th v-translate>
                      Total Partitions
                    </th>
                    <th v-translate>
                      Average Row Count
                    </th>
                    <th v-translate>
                      Minimum Row Count
                    </th>
                    <th v-translate>
                      Maximum Row Count
                    </th>
                    <th v-translate>
                      Standard Deviation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ number(partitionSummary.count, 0) }}</td>
                    <td>{{ number(partitionSummary.avg, 0) }}</td>
                    <td>{{ number(partitionSummary.min, 0) }}</td>
                    <td>{{ number(partitionSummary.max, 0) }}</td>
                    <td>{{ number(partitionSummary.stddev, 0) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="2">
              <div class="w-full h-48 flex items-center justify-center">
                <yb-loading />
              </div>
            </td>
          </tr>
        </template>
      </table>
    </tab>
    <tab title="Definition">
      <template v-if="!loading">
        <div v-show="tableDefinition">
          <button v-yb-clipboard:text="tableDefinition" class="yb-button-default mt-4">
            <yb-icon class="yb-button-icon" icon="clipboard" />
            <translate>Copy to Clipboard</translate>
          </button>
          <yb-highlight language="sql" :content="tableDefinition" class="overflow-auto whitespace-pre-wrap size-v-60 w-full" />
        </div>
        <div v-show="noTableDefinition" v-translate class="mt-6">
          Cannot display the definition for this system table
        </div>
      </template>
      <template v-else>
        <div class="w-full h-48 flex items-center justify-center">
          <yb-loading />
        </div>
      </template>
    </tab>
    <tab title="Analyze Activity">
      <label v-if="analyzeDenied">You do not have permission to view analyze log entries for this table.</label>
      <div v-else class="h-full">
        <yb-grid
          v-if="analyze && analyze.length"
          class="mt-4 h-full pb-12"
          :column-defs="analyzeColumns"
          :rows="analyze"
        />
        <label v-else translate class="mt-4">There are no analyze log entries to display.</label>
      </div>
    </tab>
    <tab title="Load Activity">
      <label v-if="loadDenied">You do not have permission to view load log entries for this table.</label>
      <div v-else class="h-full">
        <yb-grid
          v-if="load && load.length"
          class="mt-4 h-full pb-12"
          :column-defs="loadColumns"
          :rows="load"
        />
        <label v-else translate class="mt-4">There are no load log entries to display.</label>
      </div>
    </tab>
    <tab title="Privileges" class="privileges-tab">
      <yb-object-privileges
        :instance-id="instanceId"
        :database="item.database"
        :targets="[{
          object: quotedIdentifier,
          label: (item.schemaName || item.schema_name) + '.' + item.name,
          id,
          owner: item.ownerId
        }]"
        type="table"
      />
    </tab>
  </tabs>
</template>

<script>
import { tableService, jolokiaService, mbeans, app, errorHandler } from '@/services'
import * as YbCellRenderers from '@/components/YbGridCellRenderers'
import YbObjectPrivileges from '@/app/security/ObjectPrivileges.vue'
import { translate, number, bytes } from '@/filters'

export default {
  components: {
    YbObjectPrivileges
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    database: {
      type: String,
      required: true
    },
    instanceId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    schema: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      item: {},
      loadingPartitions: false,
      tableDefinition: null,
      noTableDefinition: null,
      partitionSummary: {},
      analyzeColumns: [
        { headerName: 'Start', field: 'start_time', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridTimestampCellRenderer },
        { headerName: 'Finish', field: 'end_time', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridTimestampCellRenderer },
        { headerName: 'Duration', field: 'duration', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridDurationCellRenderer },
        { headerName: 'User', field: 'rolname', minWidth: 150 },
        { headerName: 'Rows Analyzed', field: 'rows_analyzed', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridHumanizeNumber },
        { headerName: 'Total Rows', field: 'rows', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridHumanizeNumber }
      ],
      loadColumns: [
        { headerName: 'Start', field: 'start_time', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridTimestampCellRenderer },
        { headerName: 'Finish', field: 'end_time', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridTimestampCellRenderer },
        { headerName: 'Duration', field: 'duration', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridDurationCellRenderer },
        { headerName: 'User', field: 'user_name', minWidth: 150 },
        { headerName: 'Rows', field: 'inserted_rows', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridHumanizeNumber },
        { headerName: 'Error Rows', field: 'error_rows', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridHumanizeNumber },
        { headerName: 'Total Rows', field: 'rows', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridHumanizeNumber },
        { headerName: 'Data Transferred', field: 'parsed_bytes', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridBytes },
        { headerName: 'Data Written', field: 'inserted_bytes', minWidth: 150, flex: 0, cellRenderer: YbCellRenderers.YbGridBytes }
      ],
      analyze: [],
      load: [],
      analyzeDenied: false,
      loadDenied: false
    }
  },
  computed: {
    quotedIdentifier() {
      const { item } = this
      const schemaName = (item.schemaName || item.schema_name)
      return `"${schemaName}"."${item.name}"`
    }
  },
  async mounted() {
    try {
      await this.loadModel()
    } catch (e) {
      errorHandler.error(e, 'Could not load table information.')
    }
  },
  methods: {
    translate,
    number,
    bytes,
    async loadModel() {
      // Get the general info.
      this.loading = true
      const general = tableService.getTableGeneral(this.id, this.database)
      const options = {
        database: this.database
      }

      // Get the analyze log.
      jolokiaService.execute(mbeans.logAnalyze, 'retrieve', [{
        where: {
          table_id: this.id
        },
        orderBy: [
          'start_time desc'
        ],
        limit: 50,
        options
      }])
        .then((response) => {
          this.analyze = response.rows.map((r) => {
            r.duration = new Date(r.end_time).getTime() - new Date(r.start_time).getTime()
            return r
          })
        })
        .catch((response) => {
          if (String(response.error).match(/permission denied/)) {
            this.analyzeDenied = true
          } else {
            errorHandler.thrown(response, 'Could not retrieve table analyze information.')
          }
        })

      // Get the load log.
      jolokiaService.execute(mbeans.sysLoad, 'retrieve', [{
        where: {
          table_id: this.id
        },
        orderBy: [
          'start_time desc'
        ],
        limit: 50,
        options
      }])
        .then((response) => {
          this.load = response.rows.map((r) => {
            r.duration = new Date(r.end_time).getTime() - new Date(r.start_time).getTime()
            return r
          })
        })
        .catch((response) => {
          if (String(response.error).match(/permission denied/)) {
            this.loadDenied = true
          } else {
            errorHandler.thrown(response, 'Could not retrieve table load information.')
          }
        })

      // Wait on the general info.
      const item = await general
      if (!item) {
        this.$dialogs.error(`Could not load information about this table.  Please check if you have permission to view this table's content`)
        return
      }
      this.item = item
      this.loading = false

      // Do we need partition info?
      if (this.item.partitionKeys && this.item.partitionKeys.length) {
        this.loadingPartitions = true
        jolokiaService.flush()
        jolokiaService
          .execute(mbeans.sysTablePartition, 'retrieve', [
            {
              columns: ['count(*) as "count"', 'avg(count) as "avg"', 'min(count) as "min"', 'max(count) as "max"', 'stddev(count) as "stddev"'],
              where: {
                table_id: this.id,
                'count:!=': 0
              },
              options
            }
          ])
          .then((response) => {
            this.partitionSummary = response.rows[0]
          })
          .finally(() => {
            this.loadingPartitions = false
          })
        jolokiaService.flush()
      }
    },
    onSelect($event, index) {
      if (index === 1) {
        this.getTableDefinition()
      }
    },
    getTableDefinition() {
      if (!this.tableDefinition && !this.noTableDefinition) {
        if (this.item.schemaName === 'sys') {
          this.noTableDefinition = true
          return Promise.resolve('')
        }
        this.loading = true
        return jolokiaService
          .execute(mbeans.datasources, 'getTableDefinition', [this.database, this.schema, this.name])
          .then((response) => {
            this.tableDefinition = response
            return this.tableDefinition
          })
          .catch(response => app.errorResult(response))
          .finally(() => this.loading = false)
      } else {
        return Promise.resolve(this.tableDefinition)
      }
    }
  }
}
</script>

<style scoped lang="postcss">
table.is-top tr > td:first-child {
  @apply pr-4 font-semibold;
}
table.is-top tr > td {
  @apply h-8;
}
table.is-narrow th {
  @apply font-semibold;
}
table.is-narrow th,
table.is-narrow td {
  @apply px-4 text-center text-base;
}

.details-tabs {
  overflow: visible;
}
.privileges-tab {
  height: calc(100% - 38px);
}

</style>
